import { useLocation } from 'react-router-dom'
import React, { useContext } from 'react'
import HeaderVisibilityContext from '../../app/context/HeaderVisibilityContext'

export default function useShouldHideHeader() {
  const { search } = useLocation()
  const { headerVisible } = useContext(HeaderVisibilityContext)

  return React.useMemo(() => {
    // @ts-ignore
    return !!parseInt(new URLSearchParams(search).get('hideHeader')) || !headerVisible
  }, [search, headerVisible])
}

import React, { useState } from 'react'
import { Field, Formik, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import { Auth } from 'aws-amplify'
import { Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useErrorNotification } from '../../../civic-champs-shared/api/hooks/useErrorNotification'
import useSuccessNotification from '../../../civic-champs-shared/api/hooks/useSuccessNotification'
import { verifyCodeSchema } from '../../schemas'
import cn from 'classnames'
import { Steps } from 'group-application/components/valid/GroupApplicationWizardForm'


const waitFor = duration =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, duration)
  })

const useStyles = makeStyles(theme => ({
  resendCodeText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerCompleteSignUpText: {
    display: 'grid',
    textAlign: 'center',
    padding: '10px',
  },
  boldText: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
  },
}))

function ConfirmSignUp(props) {
  const { onSubmit, signUpInfo, classes, organization, goToStep } = props
  const { email, phoneNumber, cognitoSub } = signUpInfo

  const [resendingDisabled, setResendingDisabled] = useState(false)
  const showSuccess = useSuccessNotification()
  const showError = useErrorNotification()

  const handleSubmitResendCode = async () => {
    setResendingDisabled(true)
    try {
      await Auth.resendSignUp(cognitoSub)
      showSuccess('Code resend successfully')
    } catch (err) {
      showError('Error sending code', err)
    } finally {
      await waitFor(5000)
      setResendingDisabled(false)
    }
  }

  return (
    <>
      <div className={classes.headerContainer}>
        <h1>{organization.name} Application</h1>
        <p>Enter the 6-digit verification code we’ve sent to {email || phoneNumber} along with a password to finish creating your account.</p>
      </div>

      <Formik
        initialValues={{
          code: '',
        }}
        validationSchema={verifyCodeSchema}
        onSubmit={values => onSubmit(verifyCodeSchema.cast(values))}
        validateOnBlur
      >
        {({  handleSubmit, isSubmitting }) => (
          <Form>
            <Grid className={classes.container} container spacing={2} direction="column" justify="center" alignItems="center">
              <div>
                <Field
                  component={TextField}
                  name="code"
                  placeholder="Verification Code"
                  className={classes.input}
                  variant="outlined"
                  errorMessage={'Code doesn\'t match'}
                />
              </div>
              <div className={classes.resendContainer}>
                <p>Didn’t receive a code?&nbsp;
                  <a
                    onClick={handleSubmitResendCode}
                    className={cn({ [classes.disabled]: resendingDisabled })}
                  >Resend verification code</a>
                </p>
              </div>
              <div className={classes.buttons}>
                <Button
                  className={classes.outlinedButton}
                  disabled={isSubmitting}
                  onClick={() => goToStep(Steps.Initital)}
                >
                  Start Over
                </Button>
                <Button
                  type="submit"
                  className={classes.button}
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              </div>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ConfirmSignUp

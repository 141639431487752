import React, { useState } from 'react'
import { SnackbarProvider } from 'notistack'
import { Typography } from '@material-ui/core'

import ErrorBoundary from './core/ErrorBoundary'
import Routes from './Routes'
import ModalPromptProvider from 'civic-champs-shared/core/modal/ModalPromptProvider'
import Notifications from 'civic-champs-shared/core/notifications'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { UserContextProvider } from './auth/UserProvider'
import { HeaderVisibilityContextProvider } from './app/context/HeaderVisibilityContext'

const ErrorFallback = ({ error }) => (
  <>
    <Typography variant="h4">An Error Occurred. Please Refresh This Page.</Typography>
    {error && <Typography color="error">{error}</Typography>}
  </>
)

function App() {
  return (
    <HeaderVisibilityContextProvider>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <SnackbarProvider>
          <Notifications />
          <ModalPromptProvider>
            <ErrorBoundary fallback={ErrorFallback}>
              <UserContextProvider>
                <Routes />
              </UserContextProvider>
            </ErrorBoundary>
          </ModalPromptProvider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </HeaderVisibilityContextProvider>
  )
}

export default App

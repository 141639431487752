import React, { useState, useCallback } from 'react'
import { Auth } from 'aws-amplify'
import { ClientMetaData } from '@aws-amplify/auth/lib/types'
import StepWizard from 'react-step-wizard'
import { v4 as uuidv4 } from 'uuid'
import CollectEmailAndPhone from './CollectEmailAndPhone'
import ChooseUser from './ChooseUser'
import SignUp from './SignUp'
import ConfirmSignUp from './ConfirmSignUp'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import ChangePassword from './ChangePassword'
import SetPassword from './SetPassword'
import AcceptRequirements from './AcceptRequirements'
import { useGetPersonsByContact } from '../../hooks'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'
import Loading from 'civic-champs-shared/core/Loading'
import { useGoogleAnalytics } from 'civic-champs-shared/utils/useGoogleAnalytics'
import useGroupApplicationStyles from 'group-application/hooks/useGroupApplicationStyles'
import { Person } from 'civic-champs-shared/common/types'

let endpointOverride: string = ''
const env = process.env.REACT_APP_ENV || 'dev'

const DEV_ENV = ['dev', 'development'].includes(env)
if (DEV_ENV) {
  endpointOverride = process.env.REACT_APP_NGROK_API_URL || ''
}

export enum Steps {
  Initital = 1,
  ChooseUser,
  SignUp,
  ConfirmSignUp,
  Login,
  SetPassword,
  ResetPassword,
  ChangePassword,
  Final,
}

export interface GroupApplicationWizardFormProps {
  orgSlugName: string
  organization: any
  group: any
  currentUser: any
}

interface SignUpInfo {
  username: string
  password?: string
  email?: string
  phoneNumber?: string
  personId?: number
}

interface SignUpOptions {
  clientMetadata?: ClientMetaData
}

export default function GroupApplicationWizardForm(props: GroupApplicationWizardFormProps) {
  const { orgSlugName, organization, group, currentUser } = props
  const [personContacts, setPersonContacts] = useState<Person[]>([])
  const [matchedUser, setMatchedUser] = useState<Person | null>(null)
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [givenName, setGivenName] = useState('')
  const [familyName, setFamilyName] = useState('')
  const [loading, setLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(Steps.Initital)
  const [signUpInfo, setSignUpInfo] = useState<SignUpInfo>({ username: '', password: '' })

  const [getPersonsByContact] = useGetPersonsByContact()
  const showError = useErrorNotification()
  const [trackEvent] = useGoogleAnalytics()
  const classes = useGroupApplicationStyles()

  const onSubmitEmailAndPhone = useCallback(
    async ({ email, phoneNumber }) => {
      try {
        setLoading(true)
        // @ts-ignore: convert hook file to ts
        const persons = await getPersonsByContact({ orgSlugName, groupId: group.id, email, phoneNumber })
        if (persons.length > 1) {
          setPersonContacts(persons)
          setCurrentStep(Steps.ChooseUser)
        } else if (persons.length === 1) {
          const [matchedUser] = persons
          if (matchedUser.cognitoSub) {
            setMatchedUser(matchedUser)
            setCurrentStep(Steps.Login)
          } else {
            setPersonContacts(persons)
            setCurrentStep(Steps.ChooseUser)
          }
        } else if (persons.length === 0) {
          setEmail(email)
          setPhoneNumber(phoneNumber)
          setCurrentStep(Steps.SignUp)
        }
      } catch (err) {
        showError('There was a problem fetching by contact', err)
      } finally {
        setLoading(false)
      }
    },
    [getPersonsByContact, group.id, orgSlugName, showError],
  )

  const onSubmitPersonSelect = async (person: Person | null, goToStep: (step: number) => void) => {
    if (person) {
      if (person.cognitoSub) {
        setMatchedUser(person)
        setCurrentStep(Steps.Login)
        goToStep(Steps.Login)
      } else {
        setMatchedUser(person)
        setCurrentStep(Steps.SetPassword)
        goToStep(Steps.SetPassword)
      }
    } else {
      setCurrentStep(Steps.SignUp)
      goToStep(Steps.SignUp)
    }
  }

  const onResetPassword = useCallback(
    async ({ email, phoneNumber }) => {
      try {
        setLoading(true)
        await Auth.forgotPassword(email || phoneNumber)
        setCurrentStep(Steps.ChangePassword)
      } catch (err) {
        showError('There was a problem resetting your password', err)
      } finally {
        setLoading(false)
      }
    },
    [showError],
  )

  const onChangePassword = useCallback(
    async ({ contact, code, password }) => {
      try {
        setLoading(true)
        await Auth.forgotPasswordSubmit(contact, code, password)
        await Auth.signIn(contact, password)
        setCurrentStep(Steps.Login)
      } catch (err) {
        showError('There was a problem changing your password', err)
      } finally {
        setLoading(false)
      }
    },
    [showError],
  )

  const getSignUpOptions = (obj: any): SignUpOptions | undefined => {
    const filteredDict = Object.entries(obj).reduce(
      (acc, [key, value]) => ({ ...acc, ...(value ? { [key]: String(value) } : {}) }),
      {},
    )
    return Object.keys(filteredDict).length ? { clientMetadata: filteredDict } : undefined
  }

  const signUp = useCallback(
    async ({ givenName, familyName, email, phoneNumber, password, personId }) => {
      const username = uuidv4()
      const phoneNumberKey = email ? 'custom:alt_phone_number' : 'phone_number'

      const request = {
        username,
        password,
        attributes: {
          ...(email && { email }),
          ...(phoneNumber && { [phoneNumberKey]: phoneNumber }),
          given_name: givenName,
          family_name: familyName,
        },
        ...getSignUpOptions({ personId, endpointOverride }),
      }

      try {
        setLoading(true)
        await Auth.signUp(request)
        setSignUpInfo({ username, password, email, phoneNumber, personId })
        setCurrentStep(Steps.ConfirmSignUp)
        trackEvent('signup', { success: true })
      } catch (err) {
        showError('There was a problem creating your account', err)
        trackEvent('signup', { success: false })
      } finally {
        setLoading(false)
      }
    },
    [showError, trackEvent],
  )

  const onSignUp = useCallback((values, goToStep) => {
    setGivenName(values.givenName)
    setFamilyName(values.familyName)

    setCurrentStep(Steps.SetPassword)
    goToStep(Steps.SetPassword)
  }, [])

  const onSetPassword = useCallback(({ password }) => {
    return signUp({
      givenName: matchedUser?.givenName || givenName,
      familyName: matchedUser?.familyName || familyName,
      email: matchedUser?.email || email,
      phoneNumber: matchedUser?.phoneNumber || phoneNumber,
      password,
      personId: matchedUser?.id,
    })

  }, [matchedUser, signUp, givenName, familyName, phoneNumber, email])

  const onSignUpConfirm = useCallback(
    async ({ code }) => {
      setLoading(true)

      const { username, password, personId } = signUpInfo
      try {
        const options = getSignUpOptions({ personId, endpointOverride })

        await Auth.confirmSignUp(username, code, options)
        await Auth.signIn(username, password)
        setCurrentStep(Steps.Final)
        trackEvent('signupConfirm', { success: true })
      } catch (error) {
        showError('There was a problem signing in', error)
        trackEvent('signupConfirm', { success: false })
      } finally {
        setLoading(false)
      }
    },
    [signUpInfo, trackEvent, showError],
  )

  const onLogin = useCallback(
    async ({ email, phoneNumber, password }) => {
      try {
        setLoading(true)
        await Auth.signIn(email || phoneNumber, password)
        setCurrentStep(Steps.Final)
        trackEvent('login', { success: true })
      } catch (err) {
        setCurrentStep(Steps.Login)
        showError('There was a problem signing in', err)
        trackEvent('login', { success: false })
      } finally {
        setLoading(false)
      }
    },
    [showError, trackEvent],
  )

  if (loading) {
    return <Loading size={60} />
  }

  return (
    <div className="form-container">
      <StepWizard className={classes.wizardContainer} isLazyMount initialStep={currentUser?.id ? Steps.Final : currentStep}>
        <CollectEmailAndPhone
          classes={classes}
          group={group}
          organization={organization}
          onSubmit={onSubmitEmailAndPhone}
          onCancel={async () => Steps.Login}
        />
        <ChooseUser classes={classes} onSubmit={onSubmitPersonSelect} persons={personContacts} organization={organization} />
        <SignUp classes={classes} organization={organization} onSubmit={onSignUp} />
        <ConfirmSignUp
          classes={classes}
          signUpInfo={signUpInfo}
          organization={organization}
          onSubmit={onSignUpConfirm}
          onStartOver={() => Steps.Initital} />
        <Login
          classes={classes}
          group={group}
          organization={organization}
          user={matchedUser}
          onSubmit={onLogin}
          onForgotPassword={async () => Steps.ResetPassword}
        />
        <SetPassword classes={classes} onSubmit={onSetPassword} />
        <ForgotPassword classes={classes} user={matchedUser} onSubmit={onResetPassword} organization={organization} />
        <ChangePassword classes={classes} user={matchedUser} onSubmit={onChangePassword} organization={organization} />
        <AcceptRequirements group={group} organization={organization} orgSlugName={orgSlugName} person={currentUser} />
      </StepWizard>
    </div>
  )
}

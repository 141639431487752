import React, { useState } from 'react'

const HeaderVisibilityContext = React.createContext({
  headerVisible: true,
  setHeaderVisible: (value: boolean) => {}
});
export default HeaderVisibilityContext
export const HeaderVisibilityContextProvider = ({ children }: any) => {
  const [headerVisible, setHeaderVisible] = useState(true)
  return <HeaderVisibilityContext.Provider value={{ headerVisible, setHeaderVisible }}>{children}</HeaderVisibilityContext.Provider>
}
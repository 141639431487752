import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { stepThreeSchema } from '../../schemas'
import { Field, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { CheckboxField } from 'civic-champs-shared/formik/components'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { FormikEffect } from 'civic-champs-shared/question-sets/components'
import { Steps } from 'group-application/components/valid/GroupApplicationWizardForm'

function SignUp(props) {
  const { onSubmit, email, phoneNumber, personId, classes, organization, goToStep } = props
  const isAboveSmall = isWidthUp('sm', props.width)
  return (
    <>
      <div className={classes.headerContainer}>
        <h1>{organization.name} Application</h1>
        <p>
          You’ll need to make a Civic Champs account to continue the application.&nbsp;
          <a href="https://www.civicchamps.com/about-us" target="_blank">What is Civic Champs?</a>
          <br /><br />
          First, please provide your first and last name:
        </p>
      </div>
      <Formik
        initialValues={{
          email,
          phoneNumber,
          personId,
        }}
        validationSchema={stepThreeSchema}
        onSubmit={values => onSubmit(stepThreeSchema.cast(values), goToStep)}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {({ handleSubmit, isSubmitting, values, errors }) => (
          <form>
            <FormikEffect formik={{ values, errors }} />
            <Grid className={classes.container} container spacing={2} direction="column" justify="center"
                  alignItems="center">
              <div>
                <Field
                  fullWidth={!isAboveSmall}
                  component={TextField}
                  label="First Name"
                  name="givenName"
                  variant="outlined"
                  autoCapitalize="on"
                  className={classes.input}
                />
              </div>
              <div>
                <Field
                  fullWidth={!isAboveSmall}
                  component={TextField}
                  name='familyName'
                  label='Last Name'
                  variant='outlined'
                  className={classes.input}
                />
              </div>
              <div>
                <Field
                  component={CheckboxField}
                  name="olderThanThirteen"
                  label="I am older than 13"
                  labelstyle={{ font: '18px/24px Open Sans' }}
                />
              </div>
              <div className={classes.buttons}>
                <Button
                  className={classes.outlinedButton}
                  disabled={isSubmitting}
                  onClick={() => goToStep(Steps.Initital)}
                >
                  Start Over
                </Button>
                <Button
                  type="submit"
                  className={classes.button}
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              </div>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  )
}

export default withWidth()(SignUp)

import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { stepOneSchema } from '../../schemas'
import { Field, Formik } from 'formik'
import { TextField } from 'formik-material-ui'

import { PhoneField } from 'civic-champs-shared/formik/components'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

function CollectEmailAndPhone(props) {
  const { onSubmit, email, phoneNumber, organization, group, classes } = props
  const isAboveSmall = isWidthUp('sm', props.width)

  return (
    <>
      <div className={classes.headerContainer}>
        <h1>{organization.name} Application</h1>
        <p>
          To apply for {organization.name}’s Group: {group.name}, first provide either your email address or phone
          number.<br /><br />
          Note: If you have previously created a Civic Champs account, be sure to use the same email address or phone
          number that you used in the past.
        </p>
      </div>
      <Formik
        initialValues={{
          email,
          phoneNumber,
        }}
        validationSchema={stepOneSchema}
        onSubmit={values => onSubmit(stepOneSchema.cast(values))}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form>
            <Grid className={classes.container} container spacing={2} direction="column" justify="center" alignItems="center">
              <div>
                  <Field
                    fullWidth={!isAboveSmall}
                    component={TextField}
                    label="Email"
                    name="email"
                    variant="outlined"
                    className={classes.input}
                  />
              </div>
              <div>
                <Field
                  fullWidth={!isAboveSmall}
                  component={PhoneField}
                  label='Mobile Number'
                  name='phoneNumber'
                  variant='outlined'
                  className={classes.input}
                />
              </div>
              <div>
                <Button
                  type='submit'
                  className={classes.button}
                  disabled={isSubmitting || (!values.email && !values.phoneNumber)}
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              </div>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  )
}

export default withWidth()(CollectEmailAndPhone)

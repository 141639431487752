import React from 'react'
import { Button, Grid } from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { Field, Formik } from 'formik'
import { TextField } from 'formik-material-ui'

import { stepOneSchema } from '../../schemas'
import { PhoneField } from 'civic-champs-shared/formik/components'
import { Steps } from 'group-application/components/valid/GroupApplicationWizardForm'

function ForgotPassword(props) {
  const { user, onSubmit, classes, goToStep, organization } = props
  const { email, phoneNumber } = user
  const isAboveSmall = isWidthUp('sm', props.width)

  return (
    <>
      <div className={classes.headerContainer}>
        <h1>{organization.name} Application</h1>
      </div>
      <Formik
        initialValues={{
          email,
          phoneNumber,
          password: null,
        }}
        validationSchema={stepOneSchema}
        onSubmit={values => onSubmit(stepOneSchema.cast(values))}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <form>
            <Grid className={classes.container} container spacing={2} direction="column" justify="center"
                  alignItems="center">
              <div>
                {phoneNumber ? (
                  <Field
                    fullWidth={!isAboveSmall}
                    component={PhoneField}
                    label="Phone Number"
                    name="phoneNumber"
                    variant="outlined"
                    className={classes.input}
                  />
                ) : (
                  <Field
                    fullWidth={!isAboveSmall}
                    component={TextField}
                    label="Email"
                    name="email"
                    variant="outlined"
                    className={classes.input}
                  />
                )}
              </div>
              <div className={classes.buttons}>
                <Button
                  className={classes.outlinedButton}
                  disabled={isSubmitting}
                  onClick={() => goToStep(Steps.Initital)}
                >
                  Start Over
                </Button>
                <Button
                  type="submit"
                  className={classes.button}
                  disabled={isSubmitting || !isValid}
                  onClick={handleSubmit}
                >
                  Reset password
                </Button>
              </div>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  )
}

export default withWidth()(ForgotPassword)

import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { Field, Formik } from 'formik'
import withWidth from '@material-ui/core/withWidth'
import StyledOnboardingCheckboxField from 'civic-champs-shared/formik/components/StyledOnboardingCheckboxField'

function ChooseUser(props) {
  const { onSubmit, persons, goToStep, classes, organization } = props
  const initialValues = persons.reduce((acc, {id}) => {
    acc[id] = false
    return acc
  }, {})

  const formatContact = ({ givenName, familyName, email, phoneNumber }) => {
    return `${givenName} ${familyName} - ${email || phoneNumber}`
  }

  return (
    <>
      <div className={classes.headerContainer}>
        <h1>{organization.name} Application</h1>
        <p>Please confirm your identity.</p>
      </div>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          if (Object.values(values).some(v => v)) return {}
          return { error: 'Please select from the list above, or click “No, that is not me” below.' }
        }}
        onSubmit={async values => {
          const selectedPersons = Object
            .entries(values)
            .filter(([, value]) => value)
            .map(([key]) => persons.find((person) => person.id === parseInt(key)))
          let selectedPerson = selectedPersons[0]
          if (selectedPersons.length > 1) {
              const emailPerson = selectedPersons.find((person) => person.email)
              if (emailPerson) {
                selectedPerson = emailPerson
              }
          }
          await onSubmit(selectedPerson, goToStep)
        }}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ handleSubmit, isSubmitting, values, errors }) => {
        return (
          <form>
            <Grid className={classes.container} container spacing={2} direction="column" justify="center" alignItems="center">
              <div>
                {persons.map(({ id, ...rest }) => (
                  <Field
                    component={StyledOnboardingCheckboxField}
                    name={id}
                    value={id}
                    label={`I am ${formatContact(rest)}`}
                    key={id}
                  />
                ))}
                {errors.error && <p className={classes.error}>{errors.error}</p>}
              </div>

              <div className={classes.buttons}>
                <Button
                  className={classes.outlinedButton}
                  disabled={isSubmitting}
                  onClick={() => onSubmit(null, goToStep)}
                >
                  No, that is not me
                </Button>
                <Button
                  type="submit"
                  className={classes.button}
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              </div>
            </Grid>
          </form>
        )}}
      </Formik>
    </>
  )
}

export default withWidth()(ChooseUser)

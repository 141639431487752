import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { resetPasswordSchema } from '../../schemas'
import { Field, Formik, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import { PasswordField } from 'civic-champs-shared/formik/components'
import { Steps } from 'group-application/components/valid/GroupApplicationWizardForm'

function ChangePassword(props) {
  const { onSubmit, user, classes, organization, goToStep } = props
  const { email, phoneNumber } = user

  return (
    <>
      <div className={classes.headerContainer}>
        <h1>{organization.name} Application</h1>
        <p>You should have been sent a verification code. Please check your {email ? 'email' : 'phone'}</p>
      </div>

      <Formik
        initialValues={{
          contact: email || phoneNumber,
          code: '',
          password: '',
          passwordConfirm: '',
        }}
        validationSchema={resetPasswordSchema}
        onSubmit={values => onSubmit(resetPasswordSchema.cast(values))}
        validateOnBlur
      >
        {({ handleSubmit }) => (
          <Form>
            <Grid className={classes.container} container spacing={2} direction='column' justify='center'
                  alignItems='center'>
              <div>
                <Field
                  component={TextField}
                  name='code'
                  label='Verification Code'
                  className={classes.input}
                  variant='outlined'
                />
              </div>
              <div>
                <Field
                  component={PasswordField}
                  name='password'
                  variant='outlined'
                  label='Password'
                  className={classes.input}
                />
              </div>
              <div>
                <Field
                  component={PasswordField}
                  name="passwordConfirm"
                  variant="outlined"
                  label="Confirm Password"
                  className={classes.input}
                />
              </div>
              <div className={classes.buttons}>
                <Button
                  className={classes.outlinedButton}
                  onClick={() => goToStep(Steps.Initital)}
                >
                  Start Over
                </Button>
                <Button
                  type='submit'
                  className={classes.button}
                  onClick={handleSubmit}
                >
                  Change password
                </Button>
              </div>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ChangePassword

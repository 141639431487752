import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  wizardContainer: {
    width: '665px',
    border: '5px solid #4985DF',
    borderRadius: '20px',
    padding: '50px 90px',
    boxSizing: 'border-box',
    display: 'block',
    margin: '0 auto',
    gap: '25px',
    maxWidth: '100%',
    '& > div > div': {
      gap: '25px',
      display: 'flex',
      flexDirection: 'column',
    },
    [theme.breakpoints.down(600)]: {
      border: 'none',
      padding: '0 20px',
    }
  },
  resendContainer: {
    marginTop: '-25px',
    '& > p': {
      textAlign: 'center',
      maxWidth: 500,
      fontFamily: "'Nunito', sans-serif",
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '28px',
      margin: 0,
      letterSpacing: '0.25px',
      '& > a': {
        cursor: 'pointer',
        color: '#0F5DB5',
      }
    }
  },
  headerContainer: {
    gap: '25px',
    display: 'flex',
    flexDirection: 'column',
    '& > h1': {
      textAlign: 'center',
      maxWidth: 500,
      fontFamily: "'Poppins', sans-serif",
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '28px',
      lineHeight: '36px',
      margin: 0,
      letterSpacing: 0,
    },
    '& > p': {
      textAlign: 'justify',
      maxWidth: 500,
      fontFamily: "'Nunito', sans-serif",
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      margin: 0,
      letterSpacing: '0.5px',
      '& > a': {
        color: '#0F5DB5',
      }
    },
    '& > h2': {
      textAlign: 'justify',
      maxWidth: 500,
      fontFamily: "'Nunito', sans-serif",
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '22px',
      lineHeight: '28px',
      margin: 0,
      letterSpacing: '0.5px',
    }
  },
  input: {
    width: '345px',
  },
  forgotLink: {
    marginTop: '20px',
    fontSize: '18px',
    cursor: 'pointer',
  },
  container: {
    gap: '25px',
    margin: 0,
  },
  error: {
    fontFamily: "'Nunito', sans-serif",
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#BA1B1B',
    margin: 0,
    padding: 0,
    paddingLeft: '42px',
  },
  button: {
    padding: '8px 24px',
    borderRadius: '100px',
    backgroundColor: '#3776CF',
    boxShadow: 'none',
    fontFamily: "'Nunito', sans-serif",
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    width: 'auto',
    height: 'auto',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#3776CF',
    },
    '& > span': {
      color: 'white',
    },
    '&:disabled': {
      backgroundColor: '#777777'
    }
  },
  outlinedButton: {
    padding: '8px 24px',
    border: '1px solid #0F5DB5',
    borderRadius: '100px',
    backgroundColor: '#FFF',
    boxShadow: 'none',
    fontFamily: "'Nunito', sans-serif",
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    width: 'auto',
    height: 'auto',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FFF',
    },
    '& > span': {
      color: '#0F5DB5',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  }
}))
import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { setPasswordSchema } from '../../schemas'
import { Field, Formik } from 'formik'
import { PasswordField } from 'civic-champs-shared/formik/components'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

function SetPassword(props) {
  const { onSubmit, classes, onStartOver } = props
  const isAboveSmall = isWidthUp('sm', props.width)

  return (
    <>
      <Formik
        initialValues={{
          password: '',
          passwordConfirm: '',
        }}
        validationSchema={setPasswordSchema}
        onSubmit={values => onSubmit(setPasswordSchema.cast(values))}
        validateOnBlur
      >
        {({ handleSubmit, isSubmitting }) => (
          <form>
            <Grid container spacing={2} direction='column' justify='center' alignItems='center'
                  className={classes.container}>
              <div>
                <Field
                  fullWidth={!isAboveSmall}
                  component={PasswordField}
                  name='password'
                  variant='outlined'
                  label='Password'
                  className={classes.input}
                />
              </div>

              <div>
                <Field
                  fullWidth={!isAboveSmall}
                  component={PasswordField}
                  name='passwordConfirm'
                  variant='outlined'
                  label='Confirm Password'
                  className={classes.input}
                />
              </div>

              <div className={classes.buttons}>
                <Button
                  className={classes.outlinedButton}
                  disabled={isSubmitting}
                  onClick={onStartOver}
                >
                  Start Over
                </Button>
                <Button
                  type='submit'
                  className={classes.button}
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              </div>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  )
}

export default withWidth()(SetPassword)

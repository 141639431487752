import React from 'react'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import IconComponent from 'civic-champs-shared/core/icon/index'
import { clearCognitoKeys } from 'civic-champs-shared/auth/utils/cognito'

import '../styles/page-header.scss'
import { ReactComponent as LogoSvg } from '../icons/logo.svg'
import { useGoogleAnalytics } from 'civic-champs-shared/utils/useGoogleAnalytics'
import useShouldHideHeader from '../hooks/useShouldHideHeader'
import cn from 'classnames'

//TODO This was partially "borrowed" and simplified from civic-champs-scheduler's code
const LogoIcon = ({isLoggedIn}) => (
  <div className={cn("logo-icon", {['logged-in']: isLoggedIn})}>
    <LogoSvg fill="#FFFFFF" />
  </div>
)

export default function PageHeader(props) {
  const shouldHideHeader = useShouldHideHeader()
  const { currentUser } = props
  const [trackEvent] = useGoogleAnalytics()
  if (shouldHideHeader) return null

  const logout = async () => {
    try {
      await Auth.signOut()
      trackEvent('logout', { success: true })
    } catch (error) {
      console.log('error ', error)
      trackEvent('logout', { success: false })
    } finally {
      clearCognitoKeys()
    }
  }

  return (
    <div className="page-header">
      <div className={cn("page-header-head", {['page-header-head_center']: !currentUser})}>
        <Link to="#" className="page-header-logo">
          <LogoIcon isLoggedIn={!!currentUser} />
        </Link>
        <div className="page-header-welcome" style={{ marginLeft: currentUser ? '117px' : 0 }}>
          Welcome
        </div>
        {currentUser ? (
          <div className={'page-header_group'}>
            <div className={'page-header_group_user'}>
              <IconComponent name={'avatar'} height={'20px'} width={'20px'} color={'white'} style={{ marginRight: 8 }} />

              <span className={'page-header_name'}>
                {currentUser.givenName} {currentUser.familyName}
              </span>
            </div>
            <span className={'page-header_logout'} onClick={logout}>
              <span className={'page-header_hide'}>(</span>Log Out<span className={'page-header_hide'}>)</span>
            </span>
          </div>
        ) : (
          /* to match width of logo, so that everything is centered */
          <div className={'page-header_hide'} style={{ width: '117px' }} />
        )}
      </div>
    </div>
  )
}
